import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MainHttpService} from '../../core/services/main-http.service';
import {MensagemSearchModel} from '../models/mensagem-search.model';
import {ChatSearchModel} from '../models/chat-search.model';

@Injectable({providedIn: 'root'})
export class MensagemService extends MainHttpService {

    getLista(params: MensagemSearchModel): Observable<any> {
        const searchParams = `?pagina=${params.paginaAtual}&qde_por_pagina=${params.qdePorPagina}&destinatario_id=${params.destinatario.id}&&order_field=${params.campoOrdenacao}&order_direction=${params.ordemOrdenacao}`;
        const path = '/mensagens/list-all' + searchParams;
        return super.get(path);
    }

    getQdeNaoLidas(): Observable<any> {
        const path = '/mensagens/msg-nao-lidas';
        return super.get(path);
    }

    override get(params: any): Observable<any> {
        throw new Error('Não implementado');
    }

    save(params: any): Observable<any> {
        throw new Error('Não implementado');
    }

    override delete(id: any): Observable<any> {
        const path = '/mensagens/' + id;
        return super.delete(path);
    }

    getMensagensAntigas(params: ChatSearchModel) {
        const path = '/mensagens/list-all-antes?data=' + params.data + '&usuario=' + params.destinatario.id + '&perpage=' + params.qdePorPagina;
        return super.get(path);
    }
}
