import {BaseModel} from '../../../models/base.model';
import {Inject} from '@angular/core';
import {Funcoes} from '../../../_helpers/funcoes';
import {UsuarioModel} from '../../admin/models/usuario.model';
import {TipoMensagemChat} from '../../../consts/tipo-mensagem-chat';

export class ChatModel extends BaseModel {
    texto: string;
    data: Date | null;
    dataLida: Date | null;
    usuarioOrigem: UsuarioModel;
    usuarioDestino: UsuarioModel;
    idsLidas: number[];
    status: string;
    usuarioStatus: UsuarioModel | null;
    tipoMensagem: TipoMensagemChat;

    constructor(@Inject(ChatModel) init?: Partial<ChatModel>) {
        super();
        this.texto = '';
        this.dataLida = null;
        this.data = null;
        this.usuarioOrigem = new UsuarioModel();
        this.usuarioDestino = new UsuarioModel();
        this.idsLidas = [];
        this.status = '';
        this.usuarioStatus = null;
        this.tipoMensagem = TipoMensagemChat.CHAT;

        Object.assign(this, init);
    }

    static jsonToObject(jsonData: any): ChatModel {
        const retObj = Object.assign(new ChatModel(), jsonData);
        if (jsonData.data_lida) {
            retObj.dataLida = jsonData.data_lida;
        }
        retObj.usuarioOrigem = UsuarioModel.jsonToObject(retObj.usuario_origem);

        // Quando tem mudança de status, não tem usuário de destino
        if (retObj.usuario_destino) {
            retObj.usuarioDestino = UsuarioModel.jsonToObject(retObj.usuario_destino);
        }

        if (retObj.usuario_status) {
            retObj.usuarioStatus = UsuarioModel.jsonToObject(retObj.usuario_status);
        }

        if (retObj.tipo_mensagem && retObj.tipo_mensagem == TipoMensagemChat.PING) {
            retObj.tipoMensagem = TipoMensagemChat.PING;
        } else {
            retObj.tipoMensagem = TipoMensagemChat.CHAT;
        }

        delete retObj.data_lida;
        delete retObj.usuario_origem;
        delete retObj.usuario_destino;
        delete retObj.usuario_status;
        delete retObj.ids_lidas;
        delete retObj.tipo_mensagem;

        return retObj;
    }

    static toPayload(chat: ChatModel): object {
        const retObj = Object.assign({}, JSON.parse(JSON.stringify(chat)));
        retObj.data_lida = Funcoes.formatarDataHoraParaDB(chat.dataLida);
        retObj.usuario_origem = chat.usuarioOrigem;
        retObj.usuario_destino = chat.usuarioDestino;
        retObj.usuario_status = chat.usuarioStatus;
        retObj.ids_lidas = chat.idsLidas;
        retObj.tipo_mensagem = chat.tipoMensagem;

        delete retObj.dataLida;
        delete retObj.usuarioOrigem;
        delete retObj.usuarioDestino;
        delete retObj.usuarioStatus;
        delete retObj.idsLidas;
        delete retObj.tipoMensagem;

        return retObj;
    }
}
