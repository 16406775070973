import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../core/services/auth.service';
import {Subject} from 'rxjs';
import {ChatModel} from '../models/chat.model';
import {TipoMensagemChat} from '../../../consts/tipo-mensagem-chat';


@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    readonly INTERVALO_PING: number = 30; //segundos
    maxTentativas = 5;
    qdeTentativasConexao = 5;
    private socket$!: WebSocketSubject<any>;
    private socketOk = new Subject<any>();
    socketOk$ = this.socketOk.asObservable();

    private leuTodasMensagensChat = new Subject<any>();
    leuTodasMensagensChat$ = this.leuTodasMensagensChat.asObservable();

    private usuarioLogado: any;
    private intervaloPing: any;

    constructor(private _authService: AuthService) {
    }

    public connect(): void {
        this.usuarioLogado = this._authService.getAuthenticatedUser();

        if (this.usuarioLogado) {
            if (!this.socket$ || this.socket$.closed) {
                this.socket$ = webSocket(environment.webSocketChatUrl + '/' + this.usuarioLogado.token);

                // Manda ping para não desconectar depois de xx segundos
                this.intervaloPing = setInterval(() => {
                    const mensagem = new ChatModel({
                        tipoMensagem: TipoMensagemChat.PING
                    });
                    this.sendMessage(ChatModel.toPayload(mensagem));
                }, (this.INTERVALO_PING * 1000));

                this.socket$.subscribe({
                    next: (res: any) => {
                        this.qdeTentativasConexao = 0;
                    },
                    error: (err: any) => {
                        console.error('Erro na conexão com socket.');
                        console.error(err);
                        clearInterval(this.intervaloPing);
                    },
                    complete: () => {
                        console.error('Socket foi fechado.');
                        clearInterval(this.intervaloPing);
                    }
                });
            }
            this.onSocketOk();
        }
    }

    sendMessage(message: any) {
        if (this.socket$.closed) {
            if (this.qdeTentativasConexao > this.maxTentativas) {
                console.warn('Impossível conectar com socket. Atualizando a página...');
                location.reload();
            }
            console.warn('Tentando reconexão com socket...');
            this.connect();
            this.qdeTentativasConexao++;
        }
        this.socket$.next(message);
    }

    close() {
        this.socket$.complete();
        if (this.intervaloPing) {
            clearInterval(this.intervaloPing);
        }
        this.qdeTentativasConexao = 0;
    }

    estaConectado() {
        return (this.socket$ && !this.socket$.closed);
    }

    public getSocket() {
        return this.socket$;
    }

    onSocketOk() {
        this.socketOk.next(null);
    }

    onLeuTodasMensagens() {
        this.leuTodasMensagensChat.next(null);
    }
}
